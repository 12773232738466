import React from 'react';
import './App.css';
import { FaPhoneAlt, FaCheckCircle } from 'react-icons/fa'; // Added FaCheckCircle
import portrait from './images/portrait.png';

function SalesPage() {
    return (
        <div className="container text-center text-white">

            <section className="intro-section my-5">

                <img src={portrait} alt="Your Portrait" className="portrait-image mb-1" />

                <div className="hero-text">
                    <a href="tel:07743471856" className="btn btn-primary btn-lg mt-">
                        <FaPhoneAlt /> Call Now: +44&nbsp;7743&nbsp;471&nbsp;856
                    </a>
                    <br />
                    <div className="free-quote">
                        <FaCheckCircle style={{ color: '#28a745', marginRight: '10px' }} />
                        Free Same Day Quote
                    </div>
                </div>

                <br />

                <h1 className="display-4 font-weight-bold title">
                    Family Plastering Business
                </h1>

                <br />
                <br />
                <br />
            </section>

            {/* Services Section */}
            <section className="services my-5">
                <h2 className="mb-4">Our Services</h2>
                <div className="row">
                    <div className="col-md-4">
                        <div className="service-box p-4">
                            <h3>Residential Plastering</h3>
                            <p>High-quality plastering solutions for your home renovation and remodeling projects.</p>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="service-box p-4">
                            <h3>Commercial Plastering</h3>
                            <p>Professional services for offices, retail spaces, and commercial buildings.</p>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="service-box p-4">
                            <h3>Repair & Restoration</h3>
                            <p>Expert repair and restoration to bring your walls and ceilings back to life.</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Testimonials Section */}
            <section className="testimonials my-5">
                <h2 className="mb-4">What Our Clients Say</h2>
                <div className="testimonial p-4">
                    <p className="mb-0">
                        "Absolutely delighted with the plastering work done on our living room. Professional and efficient service!"
                    </p>
                    <footer className="blockquote-footer text-white mt-2">
                        John Doe, Homeowner
                    </footer>
                </div>
            </section>

            {/* Call to Action */}
            <section className="call-to-action my-5">
                <h2>Ready to Transform Your Space?</h2>
                <a href="tel:07743471856" className="btn btn-success btn-lg mt-3">
                    <FaPhoneAlt /> Get a Free Quote Now
                </a>
            </section>

            {/* Footer */}
            <footer className="mt-5">
                <p>&copy; {new Date().getFullYear()} Your Company Name. All rights reserved.</p>
            </footer>
        </div>
    );
}

export default SalesPage;