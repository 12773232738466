import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import backgroundImage from './images/background.png'; // Ensure this image exists
import SalesPage from './SalesPage';

function App() {
  return (
    <div
      className="full-background"
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <div className="overlay">
        <SalesPage />
      </div>
    </div>
  );
}

export default App;